
import { Injectable } from "@angular/core";
import 'rxjs/Rx';
import {  Router } from '@angular/router';
declare var Swal: any;
@Injectable()

export class global {
  kosmo_object = null
  kosmo_flow = true
  merchantCashierObj:any = {}
  swal(title, message, icon) {
    Swal.fire({
      heightAuto: false,
      icon: icon,
      backdrop: true,
      title: title,
      text:
        message,
    }).then((result) => {
      if (result.value == true) {
      }
    });
  }

  
  swalGeneralCustomError(text) {
    this.swal('Error', text, 'info')
  }
  swalGeneralError() {
    this.swal('Error', 'General error, please contact support', 'info')
  }
  swalGeneralSuccess() {
    this.swal('success', 'Action successfully processed', 'success')
  }

  

  choosen_merchant_id = null
  is_admin = false
  validate_admin(){
    if(this.user.id == '1'){
      this.is_admin = true
    }
    console.log('admin ',this.is_admin)
  }
 
  user:any = {}
  niceLoader = false
  auth_variable_pages = 'login';
  constructor(public router:Router) {};

  nav_(page){
    this.router.navigate([page]);
  }
  logout() {
    // this.user = {};
    // localStorage.removeItem('crypto_admin_user');
    // localStorage.removeItem('crypto_admin_user_token');
    // this.auth_variable_pages = 'login'
    // this.nav_('/login')
  }



  kevin_banks = [
    {
        "id": "BCR_RO",
        "name": "Banca Comerciala Romana",
        "countryCode": "RO",
        "logo": "https://cdn.kevin.eu/banks/images/BCR_RO.png"
    },
    {
        "id": "BELFIUS_BE",
        "name": "Belfius",
        "countryCode": "BE",
        "logo": "https://cdn.kevin.eu/banks/images/BELFIUS_BE.png"
    },
    {
        "id": "CAIXABANK_ES",
        "name": "CaixaBank",
        "countryCode": "ES",
        "logo": "https://cdn.kevin.eu/banks/images/CAIXABANK_ES.png"
    },
    {
        "id": "CGD_PT",
        "name": "CGD",
        "countryCode": "PT",
        "logo": "https://cdn.kevin.eu/banks/images/CGD_PT.png"
    },
    {
        "id": "CREDIT_AGRICOLE_FR",
        "name": "Crédit Agricole",
        "countryCode": "FR",
        "logo": "https://cdn.kevin.eu/banks/images/CREDIT_AGRICOLE_FR.png"
    },
    {
        "id": "CSAS_CZ",
        "name": "Česká spořitelna",
        "countryCode": "CZ",
        "logo": "https://cdn.kevin.eu/banks/images/CSAS_CZ.png"
    },
    {
        "id": "DANSKE_BANK_DK",
        "name": "Danske Bank",
        "countryCode": "DK",
        "logo": "https://cdn.kevin.eu/banks/images/DANSKE_BANK_DK.png"
    },
    {
        "id": "DNB_NO",
        "name": "DNB ASA",
        "countryCode": "NO",
        "logo": "https://cdn.kevin.eu/banks/images/DNB_NO.png"
    },
    {
        "id": "DSKBANK_BG",
        "name": "DSK Bank",
        "countryCode": "BG",
        "logo": "https://cdn.kevin.eu/banks/images/DSKBANK_BG.png"
    },
    {
        "id": "INTESA_SANPAOLO_IT",
        "name": "Intesa Sanpaolo",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/INTESA_SANPAOLO_IT.png"
    },
    {
        "id": "NORDEA_FI",
        "name": "Nordea",
        "countryCode": "FI",
        "logo": "https://cdn.kevin.eu/banks/images/NORDEA_FI.png"
    },
    {
        "id": "OTP_HU",
        "name": "OTP Bank",
        "countryCode": "HU",
        "logo": "https://cdn.kevin.eu/banks/images/OTP_HU.png"
    },
    {
        "id": "PIRAEUS_GR",
        "name": "Piraeus Bank",
        "countryCode": "GR",
        "logo": "https://cdn.kevin.eu/banks/images/PIRAEUS_GR.png"
    },
    {
        "id": "PKO_PL",
        "name": "PKO BP",
        "countryCode": "PL",
        "logo": "https://cdn.kevin.eu/banks/images/PKO_PL.png"
    },
    {
        "id": "RABOBANK_NL",
        "name": "Rabobank",
        "countryCode": "NL",
        "logo": "https://cdn.kevin.eu/banks/images/RABOBANK_NL.png"
    },
    {
        "id": "REVOLUT_IE",
        "name": "Revolut",
        "countryCode": "IE",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_IE.png"
    },
    {
        "id": "SLSP_SK",
        "name": "Slovenska sporitelna",
        "countryCode": "SK",
        "logo": "https://cdn.kevin.eu/banks/images/SLSP_SK.png"
    },
    // {
    //     "id": "SPARKASSEN_AT",
    //     "name": "Sparkassen",
    //     "countryCode": "AT",
    //     "logo": "https://cdn.kevin.eu/banks/images/SPARKASSEN_AT.png"
    // },
    // {
    //     "id": "SPARKASSEN_DE",
    //     "name": "Sparkassen",
    //     "countryCode": "DE",
    //     "logo": "https://cdn.kevin.eu/banks/images/SPARKASSEN_DE.png"
    // },
    {
        "id": "SWEDBANK_EE",
        "name": "Swedbank",
        "countryCode": "EE",
        "logo": "https://cdn.kevin.eu/banks/images/SWEDBANK_EE.png"
    },
    {
        "id": "SWEDBANK_LT",
        "name": "Swedbank",
        "countryCode": "LT",
        "logo": "https://cdn.kevin.eu/banks/images/SWEDBANK_LT.png"
    },
    {
        "id": "SWEDBANK_LV",
        "name": "Swedbank",
        "countryCode": "LV",
        "logo": "https://cdn.kevin.eu/banks/images/SWEDBANK_LV.png"
    },
    {
        "id": "SWEDBANK_SE",
        "name": "Swedbank",
        "countryCode": "SE",
        "logo": "https://cdn.kevin.eu/banks/images/SWEDBANK_SE.png"
    },
    {
        "id": "ZAGREBACKA_BANKA_HR",
        "name": "Zagrebačka",
        "countryCode": "HR",
        "logo": "https://cdn.kevin.eu/banks/images/ZAGREBACKA_BANKA_HR.png"
    },
    {
        "id": "BNP_PARIBAS_BE",
        "name": "BNP Paribas Fortis",
        "countryCode": "BE",
        "logo": "https://cdn.kevin.eu/banks/images/BNP_PARIBAS_BE.png"
    },
    {
        "id": "CAISSE_DEPARGNE_FR",
        "name": "Caisse d’Epargne",
        "countryCode": "FR",
        "logo": "https://cdn.kevin.eu/banks/images/CAISSE_DEPARGNE_FR.png"
    },
    {
        "id": "CSOB_CZ",
        "name": "CSOB Czech Republic",
        "countryCode": "CZ",
        "logo": "https://cdn.kevin.eu/banks/images/CSOB_CZ.png"
    },
    {
        "id": "ING_NL",
        "name": "ING Group",
        "countryCode": "NL",
        "logo": "https://cdn.kevin.eu/banks/images/ING_NL.png"
    },
    {
        "id": "INTESA_SANPAOLO_SK",
        "name": "VÚB Bank",
        "countryCode": "SK",
        "logo": "https://cdn.kevin.eu/banks/images/INTESA_SANPAOLO_SK.png"
    },
    {
        "id": "KH_HU",
        "name": "K&H Bank",
        "countryCode": "HU",
        "logo": "https://cdn.kevin.eu/banks/images/KH_HU.png"
    },
    // {
    //     "id": "N26_IE",
    //     "name": "N26",
    //     "countryCode": "IE",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_IE.png"
    // },
    {
        "id": "NBG_GR",
        "name": "National Bank of Greece",
        "countryCode": "GR",
        "logo": "https://cdn.kevin.eu/banks/images/NBG_GR.png"
    },
    {
        "id": "NORDEA_DK",
        "name": "Nordea",
        "countryCode": "DK",
        "logo": "https://cdn.kevin.eu/banks/images/NORDEA_DK.png"
    },
    {
        "id": "NORDEA_NO",
        "name": "Nordea",
        "countryCode": "NO",
        "logo": "https://cdn.kevin.eu/banks/images/NORDEA_NO.png"
    },
    {
        "id": "NORDEA_SE",
        "name": "Nordea",
        "countryCode": "SE",
        "logo": "https://cdn.kevin.eu/banks/images/NORDEA_SE.png"
    },
    {
        "id": "PEKAO_PL",
        "name": "Bank Pekao",
        "countryCode": "PL",
        "logo": "https://cdn.kevin.eu/banks/images/PEKAO_PL.png"
    },
    {
        "id": "RAIFFEISEN_AT",
        "name": "Raiffeisen Bank",
        "countryCode": "AT",
        "logo": "https://cdn.kevin.eu/banks/images/RAIFFEISEN_AT.png"
    },
    {
        "id": "SANTANDER_ES",
        "name": "Santander",
        "countryCode": "ES",
        "logo": "https://cdn.kevin.eu/banks/images/SANTANDER_ES.png"
    },
    {
        "id": "SANTANDER_TOTTA_PT",
        "name": "Banco Santander",
        "countryCode": "PT",
        "logo": "https://cdn.kevin.eu/banks/images/SANTANDER_TOTTA_PT.png"
    },
    {
        "id": "SBANK_FI",
        "name": "S-Pankki",
        "countryCode": "FI",
        "logo": "https://cdn.kevin.eu/banks/images/SBANK_FI.png"
    },
    {
        "id": "SEB_EE",
        "name": "SEB",
        "countryCode": "EE",
        "logo": "https://cdn.kevin.eu/banks/images/SEB_EE.png"
    },
    {
        "id": "SEB_LT",
        "name": "SEB",
        "countryCode": "LT",
        "logo": "https://cdn.kevin.eu/banks/images/SEB_LT.png"
    },
    {
        "id": "SEB_LV",
        "name": "SEB",
        "countryCode": "LV",
        "logo": "https://cdn.kevin.eu/banks/images/SEB_LV.png"
    },
    {
        "id": "UNICREDIT_IT",
        "name": "UniCredit",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/UNICREDIT_IT.png"
    },
    {
        "id": "ABN_NL",
        "name": "ABN AMRO NL (ABN)",
        "countryCode": "NL",
        "logo": "https://cdn.kevin.eu/banks/images/ABN_NL.png"
    },
    {
        "id": "ALPHA_GR",
        "name": "Alpha Bank",
        "countryCode": "GR",
        "logo": "https://cdn.kevin.eu/banks/images/ALPHA_GR.png"
    },
    {
        "id": "BANK_NORWEGIAN_NO",
        "name": "Bank Norwegian",
        "countryCode": "NO",
        "logo": "https://cdn.kevin.eu/banks/images/BANK_NORWEGIAN_NO.png"
    },
    {
        "id": "BBVA_ES",
        "name": "BBVA",
        "countryCode": "ES",
        "logo": "https://cdn.kevin.eu/banks/images/BBVA_ES.png"
    },
    {
        "id": "BCP_PT",
        "name": "Millennium BCP",
        "countryCode": "PT",
        "logo": "https://cdn.kevin.eu/banks/images/BCP_PT.png"
    },
    {
        "id": "BT_RO",
        "name": "BT Romania",
        "countryCode": "RO",
        "logo": "https://cdn.kevin.eu/banks/images/BT_RO.png"
    },
    {
        "id": "HANDELSBANKEN_SE",
        "name": "Svenska Handelsbanken AB",
        "countryCode": "SE",
        "logo": "https://cdn.kevin.eu/banks/images/HANDELSBANKEN_SE.png"
    },
    {
        "id": "ING_DE",
        "name": "ING Group",
        "countryCode": "DE",
        "logo": "https://cdn.kevin.eu/banks/images/ING_DE.png"
    },
    {
        "id": "JYSKEBANK_DK",
        "name": "Jyske Bank",
        "countryCode": "DK",
        "logo": "https://cdn.kevin.eu/banks/images/JYSKEBANK_DK.png"
    },
    {
        "id": "KBC_BE",
        "name": "KBC",
        "countryCode": "BE",
        "logo": "https://cdn.kevin.eu/banks/images/KBC_BE.png"
    },
    {
        "id": "KB_CZ",
        "name": "Komercni Bank",
        "countryCode": "CZ",
        "logo": "https://cdn.kevin.eu/banks/images/KB_CZ.png"
    },
    {
        "id": "LABANQUE_POSTALE_FR",
        "name": "La Banque Postale",
        "countryCode": "FR",
        "logo": "https://cdn.kevin.eu/banks/images/LABANQUE_POSTALE_FR.png"
    },
    {
        "id": "LHV_EE",
        "name": "LHV",
        "countryCode": "EE",
        "logo": "https://cdn.kevin.eu/banks/images/LHV_EE.png"
    },
    {
        "id": "LUMINOR_LT",
        "name": "Luminor",
        "countryCode": "LT",
        "logo": "https://cdn.kevin.eu/banks/images/LUMINOR_LT.png"
    },
    {
        "id": "LUMINOR_LV",
        "name": "Luminor",
        "countryCode": "LV",
        "logo": "https://cdn.kevin.eu/banks/images/LUMINOR_LV.png"
    },
    {
        "id": "OP_FINANCIAL_FI",
        "name": "Osuuspankki",
        "countryCode": "FI",
        "logo": "https://cdn.kevin.eu/banks/images/OP_FINANCIAL_FI.png"
    },
    {
        "id": "SANTANDER_PL",
        "name": "Santander",
        "countryCode": "PL",
        "logo": "https://cdn.kevin.eu/banks/images/SANTANDER_PL.png"
    },
    {
        "id": "TATRA_SK",
        "name": "Tatra Bank",
        "countryCode": "SK",
        "logo": "https://cdn.kevin.eu/banks/images/TATRA_SK.png"
    },
    {
        "id": "ALIOR_PL",
        "name": "Alior",
        "countryCode": "PL",
        "logo": "https://cdn.kevin.eu/banks/images/ALIOR_PL.png"
    },
    {
        "id": "ASNBANK_NL",
        "name": "ASN",
        "countryCode": "NL",
        "logo": "https://cdn.kevin.eu/banks/images/ASNBANK_NL.png"
    },
    {
        "id": "BANCAMPS_IT",
        "name": "Banca Monte dei Paschi di Siena (BMPS)",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/BANCAMPS_IT.png"
    },
    {
        "id": "BANCOSABADELL_ES",
        "name": "Banco Sabadell",
        "countryCode": "ES",
        "logo": "https://cdn.kevin.eu/banks/images/BANCOSABADELL_ES.png"
    },
    {
        "id": "BNP_PARIBAS_FR",
        "name": "BNP Paribas",
        "countryCode": "FR",
        "logo": "https://cdn.kevin.eu/banks/images/BNP_PARIBAS_FR.png"
    },
    {
        "id": "BPI_PT",
        "name": "BPI",
        "countryCode": "PT",
        "logo": "https://cdn.kevin.eu/banks/images/BPI_PT.png"
    },
    {
        "id": "CITADELE_LT",
        "name": "Citadele",
        "countryCode": "LT",
        "logo": "https://cdn.kevin.eu/banks/images/CITADELE_LT.png"
    },
    {
        "id": "CITADELE_LV",
        "name": "Citadele",
        "countryCode": "LV",
        "logo": "https://cdn.kevin.eu/banks/images/CITADELE_LV.png"
    },
    {
        "id": "CSOB_SK",
        "name": "CSOB Slovakia",
        "countryCode": "SK",
        "logo": "https://cdn.kevin.eu/banks/images/CSOB_SK.png"
    },
    {
        "id": "DANSKE_BANK_FI",
        "name": "Danske Bank",
        "countryCode": "FI",
        "logo": "https://cdn.kevin.eu/banks/images/DANSKE_BANK_FI.png"
    },
    {
        "id": "EUROBANK_GR",
        "name": "Eurobank",
        "countryCode": "GR",
        "logo": "https://cdn.kevin.eu/banks/images/EUROBANK_GR.png"
    },
    {
        "id": "FIBANK_BG",
        "name": "Fibank",
        "countryCode": "BG",
        "logo": "https://cdn.kevin.eu/banks/images/FIBANK_BG.png"
    },
    {
        "id": "ING_BE",
        "name": "ING Group",
        "countryCode": "BE",
        "logo": "https://cdn.kevin.eu/banks/images/ING_BE.png"
    },
    {
        "id": "LUMINOR_EE",
        "name": "Luminor",
        "countryCode": "EE",
        "logo": "https://cdn.kevin.eu/banks/images/LUMINOR_EE.png"
    },
    {
        "id": "NYKREDIT_DK",
        "name": "Nykredit",
        "countryCode": "DK",
        "logo": "https://cdn.kevin.eu/banks/images/NYKREDIT_DK.png"
    },
    {
        "id": "RAIFFEISEN_CZ",
        "name": "Raiffeisen Cz",
        "countryCode": "CZ",
        "logo": "https://cdn.kevin.eu/banks/images/RAIFFEISEN_CZ.png"
    },
    {
        "id": "RAIFFEISEN_RO",
        "name": "Raiffeisen Bank",
        "countryCode": "RO",
        "logo": "https://cdn.kevin.eu/banks/images/RAIFFEISEN_RO.png"
    },
    {
        "id": "RAIFFEISEN_SK",
        "name": "Raiffeisen Tatra Bank",
        "countryCode": "SK",
        "logo": "https://cdn.kevin.eu/banks/images/RAIFFEISEN_SK.png"
    },
    {
        "id": "SEB_SE",
        "name": "SEB",
        "countryCode": "SE",
        "logo": "https://cdn.kevin.eu/banks/images/SEB_SE.png"
    },
    {
        "id": "UNICREDIT_DE",
        "name": "HypoVereinsbank (UniCredit Bank AG)",
        "countryCode": "DE",
        "logo": "https://cdn.kevin.eu/banks/images/UNICREDIT_DE.png"
    },
    {
        "id": "ARBEJDERNES_LANDSBANK_DK",
        "name": "Arbejdernes Landsbank",
        "countryCode": "DK",
        "logo": "https://cdn.kevin.eu/banks/images/ARBEJDERNES_LANDSBANK_DK.png"
    },
    {
        "id": "ARGENTA_SPAAR_BE",
        "name": "Argenta Spaar",
        "countryCode": "BE",
        "logo": "https://cdn.kevin.eu/banks/images/ARGENTA_SPAAR_BE.png"
    },
    {
        "id": "BANCO_BPM_IT",
        "name": "Banco BPM",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/BANCO_BPM_IT.png"
    },
    {
        "id": "COOP_EE",
        "name": "Coop",
        "countryCode": "EE",
        "logo": "https://cdn.kevin.eu/banks/images/COOP_EE.png"
    },
    {
        "id": "CREDIT_MUTUEL_FR",
        "name": "Crédit Mutuel",
        "countryCode": "FR",
        "logo": "https://cdn.kevin.eu/banks/images/CREDIT_MUTUEL_FR.png"
    },
    {
        "id": "ING_ES",
        "name": "ING Group",
        "countryCode": "ES",
        "logo": "https://cdn.kevin.eu/banks/images/ING_ES.png"
    },
    {
        "id": "ING_PL",
        "name": "ING",
        "countryCode": "PL",
        "logo": "https://cdn.kevin.eu/banks/images/ING_PL.png"
    },
    {
        "id": "MONETA_CZ",
        "name": "MONETA Money Bank",
        "countryCode": "CZ",
        "logo": "https://cdn.kevin.eu/banks/images/MONETA_CZ.png"
    },
    {
        "id": "NOVO_BANCO_PT",
        "name": "Novo Banco",
        "countryCode": "PT",
        "logo": "https://cdn.kevin.eu/banks/images/NOVO_BANCO_PT.png"
    },
    {
        "id": "PRIMABANKA_SK",
        "name": "Prima banka Slovensko, a.s.",
        "countryCode": "SK",
        "logo": "https://cdn.kevin.eu/banks/images/PRIMABANKA_SK.png"
    },
    {
        "id": "RAIFFEISEN_HU",
        "name": "Raiffeisen Bank",
        "countryCode": "HU",
        "logo": "https://cdn.kevin.eu/banks/images/RAIFFEISEN_HU.png"
    },
    {
        "id": "REVOLUT_LT",
        "name": "Revolut",
        "countryCode": "LT",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_LT.png"
    },
    {
        "id": "REVOLUT_LV",
        "name": "Revolut",
        "countryCode": "LV",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_LV.png"
    },
    {
        "id": "SAASTOPANKKI_FI",
        "name": "Säästöpankki",
        "countryCode": "FI",
        "logo": "https://cdn.kevin.eu/banks/images/SAASTOPANKKI_FI.png"
    },
    {
        "id": "SNSBANK_NL",
        "name": "SNS",
        "countryCode": "NL",
        "logo": "https://cdn.kevin.eu/banks/images/SNSBANK_NL.png"
    },
    {
        "id": "UNICREDIT_BG",
        "name": "UniCredit Bulbank",
        "countryCode": "BG",
        "logo": "https://cdn.kevin.eu/banks/images/UNICREDIT_BG.png"
    },
    {
        "id": "VOLKSBANK_AT",
        "name": "Volksbank",
        "countryCode": "AT",
        "logo": "https://cdn.kevin.eu/banks/images/VOLKSBANK_AT.png"
    },
    {
        "id": "AIRBANK_CZ",
        "name": "Air Bank",
        "countryCode": "CZ",
        "logo": "https://cdn.kevin.eu/banks/images/AIRBANK_CZ.png"
    },
    {
        "id": "BANCO_MONTEPIO_PT",
        "name": "Banco Montepio",
        "countryCode": "PT",
        "logo": "https://cdn.kevin.eu/banks/images/BANCO_MONTEPIO_PT.png"
    },
    {
        "id": "BNL_IT",
        "name": "Banca Nazionale del Lavoro (BNL)",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/BNL_IT.png"
    },
    {
        "id": "CITADELE_EE",
        "name": "Citadele",
        "countryCode": "EE",
        "logo": "https://cdn.kevin.eu/banks/images/CITADELE_EE.png"
    },
    {
        "id": "DANSKE_BANK_NO",
        "name": "Danske Bank",
        "countryCode": "NO",
        "logo": "https://cdn.kevin.eu/banks/images/DANSKE_BANK_NO.png"
    },
    {
        "id": "IBERCAJA_ES",
        "name": "Ibercaja Banco",
        "countryCode": "ES",
        "logo": "https://cdn.kevin.eu/banks/images/IBERCAJA_ES.png"
    },
    {
        "id": "ING_AT",
        "name": "ING Group",
        "countryCode": "AT",
        "logo": "https://cdn.kevin.eu/banks/images/ING_AT.png"
    },
    {
        "id": "MILLENIUM_PL",
        "name": "Millenium",
        "countryCode": "PL",
        "logo": "https://cdn.kevin.eu/banks/images/MILLENIUM_PL.png"
    },
    {
        "id": "REGIOBANK_NL",
        "name": "Regio",
        "countryCode": "NL",
        "logo": "https://cdn.kevin.eu/banks/images/REGIOBANK_NL.png"
    },
    {
        "id": "REVOLUT_BE",
        "name": "Revolut",
        "countryCode": "BE",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_BE.png"
    },
    {
        "id": "REVOLUT_GR",
        "name": "Revolut",
        "countryCode": "GR",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_GR.png"
    },
    {
        "id": "SANTANDER_DE",
        "name": "Santander",
        "countryCode": "DE",
        "logo": "https://cdn.kevin.eu/banks/images/SANTANDER_DE.png"
    },
    {
        "id": "SB_LT",
        "name": "Šiaulių bankas",
        "countryCode": "LT",
        "logo": "https://cdn.kevin.eu/banks/images/SB_LT.png"
    },
    {
        "id": "SOCIETE_GENERALE_FR",
        "name": "Societe Generale",
        "countryCode": "FR",
        "logo": "https://cdn.kevin.eu/banks/images/SOCIETE_GENERALE_FR.png"
    },
    {
        "id": "SYDBANK_DK",
        "name": "Sydbank",
        "countryCode": "DK",
        "logo": "https://cdn.kevin.eu/banks/images/SYDBANK_DK.png"
    },
    {
        "id": "UNICREDIT_HU",
        "name": "UniCredit",
        "countryCode": "HU",
        "logo": "https://cdn.kevin.eu/banks/images/UNICREDIT_HU.png"
    },
    {
        "id": "UNICREDIT_RO",
        "name": "UniCredit",
        "countryCode": "RO",
        "logo": "https://cdn.kevin.eu/banks/images/UNICREDIT_RO.png"
    },
    {
        "id": "BANKINTER_ES",
        "name": "Bankinter",
        "countryCode": "ES",
        "logo": "https://cdn.kevin.eu/banks/images/BANKINTER_ES.png"
    },
    {
        "id": "BPER_BANCA_IT",
        "name": "BPER Banca",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/BPER_BANCA_IT.png"
    },
    {
        "id": "CIB_HU",
        "name": "CIB Bank",
        "countryCode": "HU",
        "logo": "https://cdn.kevin.eu/banks/images/CIB_HU.png"
    },
    {
        "id": "CIC_FR",
        "name": "Crédit Industriel et Commercial",
        "countryCode": "FR",
        "logo": "https://cdn.kevin.eu/banks/images/CIC_FR.png"
    },
    {
        "id": "DEUTSCHE_DE",
        "name": "Deutsche Bank",
        "countryCode": "DE",
        "logo": "https://cdn.kevin.eu/banks/images/DEUTSCHE_DE.png"
    },
    {
        "id": "FIO_CZ",
        "name": "Fio Banka",
        "countryCode": "CZ",
        "logo": "https://cdn.kevin.eu/banks/images/FIO_CZ.png"
    },
    {
        "id": "HELLO_BE",
        "name": "Hello bank!",
        "countryCode": "BE",
        "logo": "https://cdn.kevin.eu/banks/images/HELLO_BE.png"
    },
    {
        "id": "ING_RO",
        "name": "ING Group",
        "countryCode": "RO",
        "logo": "https://cdn.kevin.eu/banks/images/ING_RO.png"
    },
    {
        "id": "MBANK_PL",
        "name": "mBANK",
        "countryCode": "PL",
        "logo": "https://cdn.kevin.eu/banks/images/MBANK_PL.png"
    },
    {
        "id": "MEDBANK_LT",
        "name": "Medicinos Bankas",
        "countryCode": "LT",
        "logo": "https://cdn.kevin.eu/banks/images/MEDBANK_LT.png"
    },
    // {
    //     "id": "N26_GR",
    //     "name": "N26",
    //     "countryCode": "GR",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_GR.png"
    // },
    {
        "id": "POP_PANKKI_FI",
        "name": "POP Pankki",
        "countryCode": "FI",
        "logo": "https://cdn.kevin.eu/banks/images/POP_PANKKI_FI.png"
    },
    {
        "id": "REVOLUT_EE",
        "name": "Revolut",
        "countryCode": "EE",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_EE.png"
    },
    {
        "id": "SPAR_NORD_BANK_DK",
        "name": "Spar Nord Bank",
        "countryCode": "DK",
        "logo": "https://cdn.kevin.eu/banks/images/SPAR_NORD_BANK_DK.png"
    },
    {
        "id": "TRIODOS_NL",
        "name": "Triodos",
        "countryCode": "NL",
        "logo": "https://cdn.kevin.eu/banks/images/TRIODOS_NL.png"
    },
    {
        "id": "UNICREDIT_SK",
        "name": "UniCredit",
        "countryCode": "SK",
        "logo": "https://cdn.kevin.eu/banks/images/UNICREDIT_SK.png"
    },
    {
        "id": "ALANDSBANKEN_FI",
        "name": "Alandsbanken",
        "countryCode": "FI",
        "logo": "https://cdn.kevin.eu/banks/images/ALANDSBANKEN_FI.png"
    },
    {
        "id": "BANCA_CARIGE_IT",
        "name": "Banca Carige",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/BANCA_CARIGE_IT.png"
    },
    {
        "id": "BNP_PARIBAS_PL",
        "name": "BNP PARIBAS",
        "countryCode": "PL",
        "logo": "https://cdn.kevin.eu/banks/images/BNP_PARIBAS_PL.png"
    },
    {
        "id": "FINTRO_BE",
        "name": "Fintro",
        "countryCode": "BE",
        "logo": "https://cdn.kevin.eu/banks/images/FINTRO_BE.png"
    },
    {
        "id": "ING_FR",
        "name": "ING Group",
        "countryCode": "FR",
        "logo": "https://cdn.kevin.eu/banks/images/ING_FR.png"
    },
    // {
    //     "id": "N26_EE",
    //     "name": "N26",
    //     "countryCode": "EE",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_EE.png"
    // },
    {
        "id": "REVOLUT_BG",
        "name": "Revolut",
        "countryCode": "BG",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_BG.png"
    },
    {
        "id": "REVOLUT_DK",
        "name": "Revolut",
        "countryCode": "DK",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_DK.png"
    },
    {
        "id": "REVOLUT_ES",
        "name": "Revolut",
        "countryCode": "ES",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_ES.png"
    },
    {
        "id": "REVOLUT_HU",
        "name": "Revolut",
        "countryCode": "HU",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_HU.png"
    },
    {
        "id": "REVOLUT_NL",
        "name": "Revolut",
        "countryCode": "NL",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_NL.png"
    },
    {
        "id": "REVOLUT_RO",
        "name": "Revolut",
        "countryCode": "RO",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_RO.png"
    },
    {
        "id": "REVOLUT_SE",
        "name": "Revolut",
        "countryCode": "SE",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_SE.png"
    },
    {
        "id": "REVOLUT_SK",
        "name": "Revolut",
        "countryCode": "SK",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_SK.png"
    },
    {
        "id": "ACTIVOBANK_PT",
        "name": "ActivoBank",
        "countryCode": "PT",
        "logo": "https://cdn.kevin.eu/banks/images/ACTIVOBANK_PT.png"
    },
    {
        "id": "CREDITO_AGRICOLA_PT",
        "name": "Credito Agricola",
        "countryCode": "PT",
        "logo": "https://cdn.kevin.eu/banks/images/CREDITO_AGRICOLA_PT.png"
    },
    {
        "id": "DANSKE_BANK_SE",
        "name": "Danske Bank",
        "countryCode": "SE",
        "logo": "https://cdn.kevin.eu/banks/images/DANSKE_BANK_SE.png"
    },
    {
        "id": "ING_IT",
        "name": "ING Group",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/ING_IT.png"
    },
    // {
    //     "id": "N26_AT",
    //     "name": "N26",
    //     "countryCode": "AT",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_AT.png"
    // },
    // {
    //     "id": "N26_BE",
    //     "name": "N26",
    //     "countryCode": "BE",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_BE.png"
    // },
    // {
    //     "id": "N26_BG",
    //     "name": "N26",
    //     "countryCode": "BG",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_BG.png"
    // },
    // {
    //     "id": "N26_ES",
    //     "name": "N26",
    //     "countryCode": "ES",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_ES.png"
    // },
    // {
    //     "id": "N26_HU",
    //     "name": "N26",
    //     "countryCode": "HU",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_HU.png"
    // },
    // {
    //     "id": "N26_LV",
    //     "name": "N26",
    //     "countryCode": "LV",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_LV.png"
    // },
    {
        "id": "OMA_SAASTOPANKKI_FI",
        "name": "Oma Säästöpankki",
        "countryCode": "FI",
        "logo": "https://cdn.kevin.eu/banks/images/OMA_SAASTOPANKKI_FI.png"
    },
    {
        "id": "REVOLUT_CZ",
        "name": "Revolut",
        "countryCode": "CZ",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_CZ.png"
    },
    {
        "id": "REVOLUT_FR",
        "name": "Revolut",
        "countryCode": "FR",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_FR.png"
    },
    {
        "id": "REVOLUT_NO",
        "name": "Revolut",
        "countryCode": "NO",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_NO.png"
    },
    {
        "id": "REVOLUT_PL",
        "name": "Revolut",
        "countryCode": "PL",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_PL.png"
    },
    {
        "id": "COMDIRECT_DE",
        "name": "Comdirect",
        "countryCode": "DE",
        "logo": "https://cdn.kevin.eu/banks/images/COMDIRECT_DE.png"
    },
    {
        "id": "CREDIT_AGRICOLE_IT",
        "name": "Credit Agricole Italia",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/CREDIT_AGRICOLE_IT.png"
    },
    {
        "id": "EUROBIC_PT",
        "name": "EuroBIC",
        "countryCode": "PT",
        "logo": "https://cdn.kevin.eu/banks/images/EUROBIC_PT.png"
    },
    {
        "id": "MBANK_CZ",
        "name": "mBANK",
        "countryCode": "CZ",
        "logo": "https://cdn.kevin.eu/banks/images/MBANK_CZ.png"
    },
    // {
    //     "id": "N26_FR",
    //     "name": "N26",
    //     "countryCode": "FR",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_FR.png"
    // },
    // {
    //     "id": "N26_NL",
    //     "name": "N26",
    //     "countryCode": "NL",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_NL.png"
    // },
    // {
    //     "id": "N26_NO",
    //     "name": "N26",
    //     "countryCode": "NO",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_NO.png"
    // },
    // {
    //     "id": "N26_PL",
    //     "name": "N26",
    //     "countryCode": "PL",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_PL.png"
    // },
    // {
    //     "id": "N26_SK",
    //     "name": "N26",
    //     "countryCode": "SK",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_SK.png"
    // },
    {
        "id": "OPENBANK_ES",
        "name": "Openbank",
        "countryCode": "ES",
        "logo": "https://cdn.kevin.eu/banks/images/OPENBANK_ES.png"
    },
    {
        "id": "REVOLUT_AT",
        "name": "Revolut",
        "countryCode": "AT",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_AT.png"
    },
    {
        "id": "REVOLUT_FI",
        "name": "Revolut",
        "countryCode": "FI",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_FI.png"
    },
    {
        "id": "BANKINTER_PT",
        "name": "Bankinter",
        "countryCode": "PT",
        "logo": "https://cdn.kevin.eu/banks/images/BANKINTER_PT.png"
    },
    {
        "id": "HANDELSBANKEN_FI",
        "name": "Svenska Handelsbanken AB",
        "countryCode": "FI",
        "logo": "https://cdn.kevin.eu/banks/images/HANDELSBANKEN_FI.png"
    },
    // {
    //     "id": "N26_DK",
    //     "name": "N26",
    //     "countryCode": "DK",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_DK.png"
    // },
    // {
    //     "id": "N26_CZ",
    //     "name": "N26",
    //     "countryCode": "CZ",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_CZ.png"
    // },
    // {
    //     "id": "N26_FI",
    //     "name": "N26",
    //     "countryCode": "FI",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_FI.png"
    // },
    // {
    //     "id": "N26_RO",
    //     "name": "N26",
    //     "countryCode": "RO",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_RO.png"
    // },
    {
        "id": "REVOLUT_PT",
        "name": "Revolut",
        "countryCode": "PT",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_PT.png"
    },
    // {
    //     "id": "N26_DE",
    //     "name": "N26",
    //     "countryCode": "DE",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_DE.png"
    // },
    {
        "id": "SPARDA_DE",
        "name": "Spardabank",
        "countryCode": "DE",
        "logo": "https://cdn.kevin.eu/banks/images/SPARDA_DE.png"
    },
    {
        "id": "REVOLUT_GB",
        "name": "Revolut",
        "countryCode": "GB",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_GB.png"
    },
    {
        "id": "CREDITO_EMILIANO_IT",
        "name": "Credito Emiliano",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/CREDITO_EMILIANO_IT.png"
    },
    {
        "id": "HYPE_IT",
        "name": "Hype",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/HYPE_IT.png"
    },
    {
        "id": "ILLIMITY_IT",
        "name": "Illimity",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/ILLIMITY_IT.png"
    },
    // {
    //     "id": "N26_GB",
    //     "name": "N26",
    //     "countryCode": "GB",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_GB.png"
    // },
    // {
    //     "id": "N26_HR",
    //     "name": "N26",
    //     "countryCode": "HR",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_HR.png"
    // },
    // {
    //     "id": "N26_IT",
    //     "name": "N26",
    //     "countryCode": "IT",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_IT.png"
    // },
    // {
    //     "id": "N26_LT",
    //     "name": "N26",
    //     "countryCode": "LT",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_LT.png"
    // },
    // {
    //     "id": "N26_LU",
    //     "name": "N26",
    //     "countryCode": "LU",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_LU.png"
    // },
    // {
    //     "id": "N26_PT",
    //     "name": "N26",
    //     "countryCode": "PT",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_PT.png"
    // },
    // {
    //     "id": "N26_SI",
    //     "name": "N26",
    //     "countryCode": "SI",
    //     "logo": "https://cdn.kevin.eu/banks/images/N26_SI.png"
    // },
    {
        "id": "REVOLUT_IT",
        "name": "Revolut",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/REVOLUT_IT.png"
    },
    {
        "id": "SELLA_IT",
        "name": "Sella",
        "countryCode": "IT",
        "logo": "https://cdn.kevin.eu/banks/images/SELLA_IT.png"
    },
    {
        "id": "UNICAJABANCO_ES",
        "name": "Unicaja Banco",
        "countryCode": "ES",
        "logo": "https://cdn.kevin.eu/banks/images/UNICAJABANCO_ES.png"
    }
]
}
