import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { global } from '../services/global';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Facebook } from '@ionic-native/facebook/ngx';
import { WINDOW_PROVIDERS } from '../services/window.service';
import { ModalModule } from 'ngb-modal';
import { ThemeService } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { KlarnaKosmaComponent } from './klarna-kosma/klarna-kosma.component';

// import { ChartsModule } from 'ng2-charts';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    NgbModule,
    // ChartsModule,
    ModalModule,
    DeviceDetectorModule.forRoot(),
    HttpModule,
    BrowserModule,
    IonicModule.forRoot({animated:false}),
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    Ng2ImgMaxModule,
    AppRoutingModule
    
  ],
  providers: [
    ThemeService,
    Facebook,
    ApiService,
    global,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent],
  exports:[]
})
export class AppModule {}
