import { Component, NgZone, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { global } from '../services/global';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '../services/window.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public ngzone: NgZone,
    private activatedRoute: ActivatedRoute,
    public api: ApiService,
    public global: global,
    public router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
  ) {
    // if (localStorage.getItem('crypto_admin_user') && localStorage.getItem('crypto_admin_user_token')) {
    //   this.global.user = JSON.parse(localStorage.getItem('crypto_admin_user'));
    //   this.global.auth_variable_pages = 'app'
    // }else{
    //   this.global.nav_('/login')
    //   this.global.auth_variable_pages = 'login'
    // }
      this.initializeApp();
  }



  ngOnInit() {
    
  }


  initializeApp() {
    this.platform.ready().then(() => {
      
      this.splashScreen.hide();
    });
  }
  
}


