import { Http, RequestOptions } from "@angular/http";
import { Injectable } from "@angular/core";
import 'rxjs/Rx';
import { DeviceDetectorService } from 'ngx-device-detector';
import { global } from '../services/global';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from "@angular/common/http";

@Injectable()

export class ApiService {
    
    // public ServerUrl = 'http://ec2-52-28-200-72.eu-central-1.compute.amazonaws.com/api/';
    // public ServerUrl = 'https://secure.bitandpay.com/api/';
    // public ServerUrl = 'https://secure.payment-gateway.technology/api/';
    // public ServerUrl = 'https://staging.payment-gateway.technology/api/';
    public ServerUrl = 'https://secure.bitandpay.com/api/';
    // public ServerUrl = 'https://breezy-meadow-gwdkuc2ukkgx.vapor-farm-g1.com/api/';
    constructor(public activatedRoute:ActivatedRoute,public global: global,private deviceService: DeviceDetectorService, private http: Http) { };





        
    async sendRequest_2(url: string, obj: any): Promise<Array<any>> {
        // this.global.niceLoader = true
        return new Promise<Array<any>>(async (resolve, reject) => {

            let send_obj =  {
               
            }
            let body = new FormData();
            if(this.global.user != {}){
                obj.user_id = this.global.user.id,
                obj.token = localStorage.getItem('crypto_admin_user_token')
            }
            body.append('obj', JSON.stringify(obj));
            const headers:any = { 'merchant-token': ''};
            try {
                let answer = await this.http.post(url, send_obj, {headers}).map(res => res.json()).do((data) => { }).toPromise();
                if(answer.permission == 'Permission denied'){
                    this.global.logout();
                    return
                }
                resolve(answer);
            } catch (err) {
                reject(err);
            } finally {
                // this.global.niceLoader = false
            }
        });
    }

    async get_sendRequest(url: string, obj: any): Promise<Array<any>> {
        this.global.niceLoader = true
        return new Promise<Array<any>>(async (resolve, reject) => {
            let body = new FormData();
            if(this.global.user != {}){
                obj.user_id = this.global.user.id,
                obj.token = localStorage.getItem('crypto_admin_user_token')
            }
            body.append('obj', JSON.stringify(obj));
            const headers:any = { 'Content-Type': 'application/json'};
            // // let headers = new Headers({ 'Content-Type': 'application/json' });
            // let options = new RequestOptions({ headers: headers });
            // content_copy
let options = {
//   headers: HttpHeaders | {[header: string]: string | string[]},
  observe: 'body',
  params:  obj
}

            try {
                let answer = await this.http.get(this.ServerUrl + '' + url,options).map(res => res.json()).do((data) => { }).toPromise();
                if(answer.permission == 'Permission denied'){
                    this.global.logout();
                    return
                }
                resolve(answer);
            } catch (err) {
                reject(err);
            } finally {
                this.global.niceLoader = false
            }
        });
    }

    async sendRequest(url: string, obj: any): Promise<Array<any>> {
        this.global.niceLoader = true
        return new Promise<Array<any>>(async (resolve, reject) => {
            let body = new FormData();
            if(this.global.user != {}){
                obj.user_id = this.global.user.id,
                obj.token = localStorage.getItem('crypto_admin_user_token')
            }
            body.append('obj', JSON.stringify(obj));
            try {
                let answer = await this.http.post(this.ServerUrl + '' + url, body).map(res => res.json()).do((data) => { }).toPromise();
                if(answer.permission == 'Permission denied'){
                    this.global.logout();
                    return
                }
                resolve(answer);
            } catch (err) {
                reject(err);
            } finally {
                this.global.niceLoader = false
            }
        });
    }

    async getImage(url: string, obj: Object){
              
            let body = new FormData();
            body.append('obj', JSON.stringify(obj));
        
                let answer = await this.http.post(this.ServerUrl + '' + url, body).do((data) => { }).toPromise();
                return answer;
          
        };
    



    async fileUpload_client(url: string, obj: Object, file: any): Promise<Array<any>> {
        return new Promise<Array<any>>(async (resolve, reject) => {
            let body = new FormData();
            body.append('ionicfile', file);

            try {
                let answer = await this.http.post(this.ServerUrl + url, body).map(res => res.json()).do((data) => { }).toPromise();
                resolve(answer);
            } catch (err) {
                reject(err);
            } finally {
                //loading.dismiss();
            }
        });
    }

}
